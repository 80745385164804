var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"#68D389","spinner":"bar-fade-scale"}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"#68D389","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"text-left":"","align-self-center":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":_vm.$vuetify.breakpoint.name == 'xs' || _vm.$vuetify.breakpoint.name == 'sm'
        ? '100vw'
        : _vm.$vuetify.breakpoint.name == 'md'
        ? '80vw'
        : _vm.$vuetify.breakpoint.name == 'lg'
        ? '50vw'
        : '35vw'},model:{value:(_vm.slotConfirmationDialog),callback:function ($$v) {_vm.slotConfirmationDialog=$$v},expression:"slotConfirmationDialog"}},[_c('v-card',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('SlotConfirmation',{key:_vm.selectedSlot._id,attrs:{"storage":_vm.programme,"selectedSlot":_vm.selectedSlot},on:{"stepper":_vm.winStepper}})],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"lg11":"","xl11":"","text-left":""}},[_c('v-card',{attrs:{"color":"#F7F7F7","tile":"","flat":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-4":""}},[_c('v-flex',{attrs:{"xs12":"","pt-6":""}},[_c('span',{staticClass:"itemHeading2"},[_vm._v(" BOOKING SUMMARY")])]),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[_c('span',{staticClass:"itemTags",staticStyle:{"color":"#9e9e9e"}},[_vm._v(" Package ")])]),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[_c('span',{staticClass:"tableValue1"},[_vm._v(" "+_vm._s(_vm.$route.query.name)+" ")]),_c('span',{staticStyle:{"font-family":"poppinssemibold","font-size":"14px","color":"#414141"}},[_vm._v(" "+_vm._s(_vm.$route.query.caption)+" ")])]),_c('v-flex',{attrs:{"xs12":"","pt-4":""}},[_c('span',{staticClass:"itemTags",staticStyle:{"color":"#9e9e9e"}},[_vm._v(" Selected Date ")])]),_c('v-flex',{attrs:{"xs12":"","md12":"","align-self-center":""}},[_c('span',{staticClass:"itemHeading2"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.newDate))+" ")])])],1),(_vm.newDate)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","px-4":"","pb-4":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticClass:"itemTags",staticStyle:{"color":"#9e9e9e"}},[_vm._v(" Select Convenient Slot ")])]),_c('v-flex',{attrs:{"xs12":""}},_vm._l((_vm.storage),function(item){return _c('v-card',{key:item._id,staticClass:"my-2",attrs:{"flat":"","ripple":false,"color":_vm.selectedSlot._id == item._id
                  ? '#68D389'
                  : item.onlineAvailable == 0
                  ? '#720000'
                  : '#FFFFFF'},on:{"click":function($event){item.onlineAvailable != 0 ? (_vm.selectedSlot = item) : {}}}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('span',{staticClass:"tableValue1",style:({
                          color:
                            _vm.selectedSlot._id == item._id ||
                            item.onlineAvailable == 0
                              ? '#FFFFFF'
                              : '#000000',
                        })},[_vm._v(" "+_vm._s(_vm.formatTime(item.startTime))+" - "+_vm._s(_vm.formatTime(item.endTime))+" ")])]),(
                        item.isBasedOnSeat == false && item.isProgram == true
                      )?_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('span',{staticClass:"tableValue1",style:({
                          color:
                            _vm.selectedSlot._id == item._id ||
                            item.onlineAvailable == 0
                              ? '#FFFFFF'
                              : '#000000',
                        })},[(item.vehicleAvailable != 0)?_c('span',[_vm._v("    "+_vm._s(item.vehicleAvailable)+" ")]):_c('span',[_vm._v(" No ")]),_c('span',[_vm._v("Vehicle Available")])])]):_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('span',{staticClass:"tableValue1",style:({
                          color:
                            _vm.selectedSlot._id == item._id ||
                            item.onlineAvailable == 0
                              ? '#FFFFFF'
                              : '#000000',
                        })},[(item.onlineAvailable != 0)?_c('span',[_vm._v(" "+_vm._s(item.onlineAvailable)+" ")]):_c('span',[_vm._v(" No ")]),_c('span',[_vm._v("Seats Available ")])])])],1)],1)],1)],1)}),1),_c('v-flex',{attrs:{"xs12":"","py-4":""}},[_c('v-divider')],1),_c('v-flex',{attrs:{"xs12":""}},[(_vm.storage.length < 1)?_c('span',{staticClass:"itemTags",staticStyle:{"color":"#000000"}},[_vm._v(" No Slot Available on "+_vm._s(_vm.formatDate(_vm.newDate))+" ")]):_vm._e()]),(_vm.selectedSlot.onlineAvailable > 0)?[_c('v-flex',{attrs:{"text-center":"","xs12":"","py-4":""}},[(_vm.newDate)?_c('span',{staticClass:"itemHeading2"},[_vm._v("Note")]):_vm._e(),_c('br'),_c('span',{staticClass:"tableValue1"},[_vm._v("This program will be changed to "+_vm._s(_vm.formatDate(_vm.newDate))+" ")])]),_c('v-flex',{attrs:{"xs12":"","py-4":""}},[_c('v-btn',{attrs:{"block":"","dark":"","depressed":"","color":"#68D389","ripple":false},on:{"click":function($event){_vm.reschuduleconformation = true}}},[_vm._v(" Submit ")])],1)]:_vm._e()],2):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":_vm.$vuetify.breakpoint.name == 'xs' || _vm.$vuetify.breakpoint.name == 'sm'
        ? '100vw'
        : _vm.$vuetify.breakpoint.name == 'md'
        ? '80vw'
        : _vm.$vuetify.breakpoint.name == 'lg'
        ? '50vw'
        : '35vw'},model:{value:(_vm.reschuduleconformation),callback:function ($$v) {_vm.reschuduleconformation=$$v},expression:"reschuduleconformation"}},[_c('v-card',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":""}},[_c('v-flex',{attrs:{"xs12":"","pa-2":"","text-center":""}},[_c('span',{staticClass:"itemHeading2"},[_vm._v("Are you certain you wish to reschedule?")])]),_c('v-flex',{attrs:{"xs12":"","pt-2":"","pb-3":""}},[(_vm.newDate)?_c('span',{staticClass:"tableValue1"},[_vm._v("Note :")]):_vm._e(),_c('span',{staticClass:"tableValue1"},[_vm._v("This program will be changed to "+_vm._s(_vm.formatDate(_vm.newDate))+" ")])]),_c('v-flex',{attrs:{"xs4":"","pb-3":""}},[_c('v-btn',{attrs:{"dark":"","depressed":"","color":"red"},on:{"click":function($event){_vm.reschuduleconformation = false}}},[_vm._v("CANCEL")])],1),_c('v-flex',{attrs:{"xs4":"","pb-3":""}},[_c('v-btn',{attrs:{"block":"","dark":"","depressed":"","color":"#68D389"},on:{"click":_vm.validateInput}},[_vm._v("SUBMIT")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }