<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end>
      <v-flex xs12 sm6 md3 pa-5>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              label="From Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fromDate"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 sm6 md3 pa-5>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDate"
              label="To Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 sm6 md3 lg3 xl4 pa-5>
        <v-select
          v-model="selectedFilter"
          :items="dateFilter"
          item-text="title"
          item-value="value"
          outlined
          dense
          label="Date"
        ></v-select>
      </v-flex>
      <v-flex xs3 align-self-center>
        <v-spacer></v-spacer>
      </v-flex>
      <v-flex
        align-self-start
        xs12
        sm6
        md3
        lg2
        xl4
        v-if="purchaseData.length > 0"
      >
        <a
          target="_blank"
          :href="
            'https://api.parambikulam.org/purchase/getDetailedReportPdf?from=' +
            fromDate +
            '&to=' +
            toDate +
            '&filter=' +
            selectedFilter
          "
          download
          class="downlink"
        >
          <span
            style="
              font-size: 16px;
              font-family: poppinssemibold;
              color: #68d389;
            "
          >
            Download PDF</span
          >
        </a>
      </v-flex>
      <v-flex
        align-self-start
        xs12
        sm6
        md3
        lg2
        xl4
        v-if="purchaseData.length > 0"
      >
        <download-excel :data="json_data" :fields="json_fields">
          <span
            style="
              font-size: 16px;
              font-family: poppinssemibold;
              color: #68d389;
              cursor: pointer;
            "
          >
            Download Excel</span
          >
        </download-excel>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="purchaseData.length > 0">
      <v-flex xs12 pt-4>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Sl. No</th>
                <th class="text-left">Order No</th>
                <th class="text-left">Invoice No</th>
                <th class="text-left">
                  Name<span style="color: white">_</span>of<span style="color: white">_</span>Customer
                </th>
                <th class="text-left">
                  <span style="color: white">____________</span
                  >Item<span style="color: white">_</span>and<span style="color: white">_</span>Description<span style="color: white"
                    >____________</span
                  >
                </th>
                <th class="text-left">Quantity<span style="color: white">_</span>Purchased</th>
                <th class="text-left">Phone</th>
                <th class="text-center">Email</th>
                <th class="text-left">Address</th>
                <th class="text-left">Date<span style="color: white">_</span>of<span style="color: white">_</span>placing of order</th>
                <th class="text-left">Order<span style="color: white">_</span>dispatched on</th>
                <th class="text-left">Tracking<span style="color: white">_</span>details</th>
                <th class="text-left">Order<span style="color: white">_</span>delivered on</th>
                <th class="text-left">
                  Amount settled to account(Total-PG charges..etc)
                </th>
                <th class="text-left">Amount(Total)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="height: 120px"
                v-for="(item, i) in purchaseData"
                :key="i"
              >
                <td v-if="purchaseData">
                  <span v-if="currentPage > 1">{{
                    (currentPage - 1) * count + i + 1
                  }}</span>
                  <span v-else>{{ i + 1 }}</span>
                </td>
                <td>{{ item.txnid }}</td>
                <td>
                  {{ item.receiptno }}
                </td>
                <td>
                  {{ item.payment.name }}
                </td>
                <td class="text-center">
                  <span class="text-center"
                    ><b>{{ item.product.name }}</b></span
                  >
                  <br />
                  <span class="text-left" v-html="item.unitname"></span>
                  <br />
                  <span class="text-left" v-html="item.sizename"></span>
                  <br />
                  <v-icon
                    small
                    @click="
                      showPopup(item.product.name, item.product.description)
                    "
                    >mdi-eye</v-icon
                  >
                </td>
                <td>
                  {{ item.quantity }}
                </td>
                <td>
                  {{ item.payment.deliveryaddress.phone }}
                </td>
                <td>
                  {{ item.payment.deliveryaddress.email }}
                </td>
                <td>
                  <span v-if="item.payment.deliveryaddress">
                    {{ item.payment.deliveryaddress.streetAddress }}
                  </span>
                </td>
                <td>
                  {{ item.payment.init_date.slice(0, 10) }}
                </td>
                <td>
                  <span v-if="item.shipped_date">
                    {{ item.shipped_date.slice(0, 10) }}
                  </span>
                  <span v-else>Not yet shipped</span>
                </td>
                <td>
                  <span v-if="item.trackingid">
                    {{ item.courierservice }}
                    <br />
                    Tracking_ID: {{ item.trackingid }}</span
                  >
                  <span v-else>Not yet shipped</span>
                </td>

                <td>
                  <span v-if="item.delivered_date">{{
                    item.delivered_date.slice(0, 10)
                  }}</span>
                  <span v-else>Not yet delivered</span>
                </td>
                <td>{{ item.amountWithoutPG }} INR</td>
                <td>{{ Math.round(item.total) }} INR</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
    <v-layout wrap v-else>
      <v-flex xs12 py-4>
        <span style="font-family: poppinsregular"> No Purchase found!! </span>
      </v-flex>
    </v-layout>
    <v-dialog v-model="showDesc" max-width="900px">
      <v-card>
        <v-card-title>
          <span style="font-family: poppinsbold" v-html="heads"></span>
        </v-card-title>

        <v-card-subtitle style="padding: 5%">
          <span v-html="description"></span>
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="showDesc = false">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageLoader from "@/components/Common/imageLoader";
import axios from "axios";
export default {
  // props: ["booking", "pages", "count"],
  data() {
    return {
      currentPage: 1,
      cancelOrderDialog: false,
      cancelFullDialog: false,
      orderItems: [],
      programData: {},
      cancelAll: false,
      progName: "",
      bookingsId: "",
      ticketsId: "",
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      isFullRefund: false,
      checkbox: false,
      allChecked: false,
      ticketIds: [],
      selectedDialog: false,
      totalSelectectedAmt: 0,
      cottageTotal: 0,
      purchaseData: [],
      pages: 0,
      count: 12,
      selectedFilter: "",
      dateFilter: [
        { title: "Past month", value: "1" },
        { title: "Past three months", value: "2" },
        { title: "Past financial year", value: "3" },
      ],
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      showDesc: false,
      description: "",
      heads: "",

      excel: [],
      json_fields: {
        Order_No: "txnid",
        Invoice_No: "receiptno",
        "Customer Name": "payment.name",
        "Item Name": "product.name",
        "Item Type": "unitname",
        "Item Size": "sizename",
        "Quantity Purchased": "quantity",
        "Phone No": "payment.deliveryaddress.phone",
        Email: "payment.deliveryaddress.email",
        Address: "payment.deliveryaddress.streetAddress",
        "Date of placing of order": "payment.init_date",
        "Order Dispatched On": "shipped_date",
        "Courier Service": "courierservice",
        "Tracking ID": "trackingid",
        "Order Delivered on": "delivered_date",
        "Amount settled(total-PG)": "amountWithoutPG",
        "Amount Paid(Total)": "total",
      },
      json_fieldsReservation: {
        ProgrameName: "programmeName",
        BookingDate: "bookingDate",
        "Seats Reserved": "reserved",
        StartTime: "slotDetail.startTime",
        EndTime: "slotDetail.endTime",
      },
      json_data: [],
      json_dataReservation: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  components: {
    // ImageLoader,
  },
  watch: {
    currentPage() {
      this.getData();
    },
    selectedFilter() {
      if (this.currentPage > 1) this.currentPage = 1;

      this.fromDate = null;
      this.toDate = null;
      this.getData();
      // this.getExcel();
    },
    fromDate() {
      this.selectedFilter = null;
      this.getData();
      // this.getExcel();
    },
    toDate() {
      this.selectedFilter = null;
      this.getData();
      // this.getExcel();
    },
  },
  mounted() {
    // console.log("hk", this.booking);
    // this.selectedFilter = this.dateFilter[0].title;
    if (this.fromDate) {
      this.fromDate.setDate(this.fromDate.getDate() - 3 * 31);
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    this.getData();
  },
  methods: {
    showPopup(heads, item) {
      this.description = item;
      this.heads = heads;
      this.showDesc = true;
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate,
          to: this.toDate,
          count: this.count,
          page: this.currentPage,
          filter: this.selectedFilter,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.purchaseData = response.data.data;
          this.excel = response.data.data;
          this.json_data = this.excel;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>

<style scoped>
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
td {
  font-family: poppinsregular;
}

th {
  font-family: poppinssemibold;
}
</style>