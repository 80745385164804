<template>
  <div>
    <v-layout wrap style="font-family: poppinsregular">
      <v-flex lg12 xl12>
        <v-snackbar
          v-model="showSnackBar"
          color="#FDCE48"
          right
          :timeout="timeout"
        >
          <v-layout wrap justify-center>
            <v-flex text-left class="align-self-center">
              <span style="color: #000">
                {{ msg }}
              </span>
            </v-flex>
            <v-flex text-right>
              <v-btn small :ripple="false" text @click="showSnackBar = false">
                <v-icon style="color: #000">mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-snackbar>
        <v-layout wrap justify-center py-2>
          <v-flex xs12 text-left px-2>
            <span class="itemHeading">Edit Package</span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-2>
          <v-flex xs12 sm6 md4 pa-2>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="packageRate.fromDate"
                  label="Please Select the Start Date"
                  readonly
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="packageRate.fromDate"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md4 pa-2>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="packageRate.toDate"
                  label="Please Select the End Date"
                  outlined
                  dense
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="packageRate.toDate"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex text-left pb-4>
            <span style="font-family: poppinssemibold; font-size: 14px"
              >Please specify package rate for different guests (in Rs.)
              <sup>*</sup></span
            >
          </v-flex>
          <v-flex xs12 hidden-sm-and-down>
            <v-layout justify-center>
              <v-flex xs2 md3> </v-flex>
              <v-flex md3 text-left>
                <span class="tableHeading"> Weekdays </span>
              </v-flex>

              <v-flex md3 text-left>
                <span class="tableHeading"> Weekends And Holidays</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify- pt-2 v-if="programData.bookingAvailability">
              <v-flex xs12 v-if="programData.bookingAvailability.indian" text-left>
                <v-card outlined style="height: 80%">
                  <v-layout wrap justify-center py-4 px-4>
                    <v-flex xs2 md3>
                      <span class="tableSide"> Indian </span>
                    </v-flex>
                    <v-flex md3 text-left>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.indian"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex md3 text-left>
                      <v-text-field
                        class="tableValue"
                        v-model="packageRate.holidays.indian"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.foreigner" text-left>
                <v-card outlined style="height: 80%">
                  <v-layout wrap justify-center py-4 px-4>
                    <v-flex xs2 md3>
                      <span class="tableSide"> Foreigner </span>
                    </v-flex>
                    <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.foreigner"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.holidays.foreigner"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <!-- <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue" type="number"
                        v-model="packageRate.holidays.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex> -->
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1  v-if="programData.bookingAvailability.children" text-left>
                <v-card outlined style="height: 80%" >
                  <v-layout  wrap justify-center py-4 px-4>
                    <v-flex xs2 md3>
                      <span class="tableSide"> Children </span>
                    </v-flex>
                    <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex md3 text-center>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.holidays.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-layout wrap justify-start>
                <v-flex text-left xs12 md3>
                  <span style="font-family: poppinssemibold; font-size: 14px"
                    >Do you want to add extra per head</span
                  >
                </v-flex>
                <v-flex xs2 md1>
                  <v-checkbox v-model="packageRate.isExtraPerHeadAvailable"></v-checkbox>
                </v-flex>
              </v-layout>
              <v-flex xs12 pt-1 v-if="packageRate.isExtraPerHeadAvailable" text-left>
                <v-layout justify-center>
                  <v-flex xs2 md3> </v-flex>
                  <v-flex md3 text-left  v-if="programData.bookingAvailability.indian">
                    <span class="tableHeading"> Indian </span>
                  </v-flex>
                  <v-flex md3 text-left v-if="programData.bookingAvailability.foreigner">
                    <span class="tableHeading">Foreigner</span>
                  </v-flex>
                  <v-flex md3 text-left v-if="programData.bookingAvailability.children">
                    <span class="tableHeading">Children</span>
                  </v-flex>
                </v-layout>
                <v-card outlined style="height: 80%">
                  <v-layout wrap justify-center py-4 px-4>
                    <v-flex xs2 md3 text-left>
                      <span class="tableSide"> Extra per head </span>
                    </v-flex>
                    <v-flex md3 text-center v-if="programData.bookingAvailability.indian">
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.indian"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex md3 text-center v-if="programData.bookingAvailability.foreigner">
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.foreigner"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                    <v-flex md3 text-center v-if="programData.bookingAvailability.children">
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
         <v-layout wrap justify-center pt-4>
          <v-flex xs12 hidden-md-and-up>
            <v-layout wrap justify-center pt-2 v-if="programData.bookingAvailability">
              <v-flex xs6 align-self-center class="pb-2">
                <span class="tableSide">Package Rate</span>
              </v-flex>
              <v-flex xs12>
                <v-flex xs12>
                  <span class="tableHeading"> Weekdays </span>
                </v-flex>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.indian">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Indian
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.indian"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                v-if="programData.bookingAvailability.foreigner"
                xs12
                pt-1
              >
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Foreigner
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.foreigner"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.children">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Children
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-2 v-if="programData.bookingAvailability">
              <v-flex xs12>
                <v-flex xs12>
                  <span class="tableHeading">Weekends And Holidays</span>
                </v-flex>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.indian">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Indian
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.holidays.indian"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                xs12
                pt-1
                v-if="programData.bookingAvailability.foreigner"
              >
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Foreigner
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.holidays.foreigner"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.children">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Children
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.holidays.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex text-left xs10 md3>
                <span style="font-family: poppinssemibold; font-size: 14px"
                  >Do you want to add extra per head</span
                >
              </v-flex>
              <v-flex xs2 md1>
                <v-checkbox
                  v-model="packageRate.isExtraPerHeadAvailable"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              pt-2
              v-if="packageRate.isExtraPerHeadAvailable"
            >
              <v-flex xs12>
                <v-flex xs12>
                  <span class="tableHeading"> Extra per head</span>
                </v-flex>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.indian">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Indian
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.indian"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                xs12
                pt-1
                v-if="programData.bookingAvailability.foreigner"
              >
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Foreigner
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.foreigner"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 pt-1 v-if="programData.bookingAvailability.children">
                <v-card outlined>
                  <v-layout wrap justify-start py-4 px-4>
                    <v-flex xs6>
                      <span class="tableSide" style="color: #606060">
                        Children
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        class="tableValue"
                        type="number"
                        v-model="packageRate.extraperhead.children"
                        required
                        style="width: 90% !important"
                        dense
                        solo
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex text-left>
            <span style="font-family: poppinssemibold; font-size: 14px">
              Please mention the various package facilities available
            </span>

            <v-combobox
              v-model="packageRate.packageFacility"
              :items="items"
              chips
              clearable
              label="Package Facilities"
              multiple
              solo
              class="pt-3"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item }}</strong
                  >&nbsp;
                </v-chip>
              </template>
            </v-combobox>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-end>
          <v-flex md3 xs12>
            <v-btn
              block
              tile
              color="#68D389"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              <span style="color: #ffffff">Save Changes</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["id", "progId"],
  data() {
    return {
      test: null,
      fromDate: new Date().toISOString().substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      programId: null,
      isProgramAdded: true,

      items: [
        "Lunch",
        "Toilet",
        "Shopping Facility",
        "Drinking Water",
        "Information Center",
      ],
      programData:[],
      packageRate: {
        program: null,
        fromDate: new Date().toISOString().substr(0, 10),
        toDate: new Date().toISOString().substr(0, 10),
        indian: null,
        children: null,
        foreigner: null,
        isExtraPerHeadAvailable: false,
        // foreignStudent: null,
        // bonafiedStudent: null,
        discount: null,
        packageFacility: [],
        // weekend: {
        //   indian: null,
        //   children: null,
        //   foreigner: null,
        //   foreignStudent: null,
        //   bonafiedStudent: null,
        // },
        holidays: {
          indian: null,
          children: null,
          foreigner: null,
          // foreignStudent: null,
          // bonafiedStudent: null,
        },
        extraperhead: {
          indian: null,
          children: null,
          foreigner: null,
          // foreignStudent: null,
          // bonafiedStudent: null,
        },
        packagerateid: null,
      },
      details: [],
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  beforeMount() {
    this.getData();
    this. getProgram();
  },
  watch: {
    isExtraPerHeadAvailable() {
      if(!this.packageRate.isExtraPerHeadAvailable){
         Object.keys(this.packageRate.extraperhead).forEach((key) => (this.packageRate.extraperhead[key] =null));
      }
    },
  },
  methods: {
    validateInput() {
      // if (!this.packageRate.indian) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.children) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.foreigner) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // }
      // else if (!this.packageRate.weekend.indian) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.weekend.children) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.weekend.foreigner) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // }
      // else if (!this.packageRate.holidays.indian) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.holidays.children) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.holidays.foreigner) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.extraperhead.indian) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.extraperhead.children) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.packageRate.extraperhead.foreigner) {
      //   this.msg = "Please Provide Value";
      //   this.showSnackBar = true;
      //   return;
      // } else {
      //   this.editPackage();
      // }
      this.editPackage();
    },
    remove(item) {
      this.packageRate.packageFacility.splice(
        this.packageRate.packageFacility.indexOf(item),
        1
      );
      this.packageRate.packageFacility = [...this.packageRate.packageFacility];
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/packagerate/get",
        params: {
          id: this.id,
          //   date: new Date(),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.details = response.data.data;
          this.packageRate = this.details;
          this.packageRate.fromDate = this.packageRate.fromDate.slice(0, 10);
          this.packageRate.toDate = this.packageRate.toDate.slice(0, 10);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     getProgram() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/programme/adminget",
        params: {
          id: this.progId,
          //   date: new Date(),
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // if (response.data.package) this.pricing = response.data.package;
          if (response.data.status) {
            this.programData = response.data.data;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editPackage() {
      this.packageRate.packagerateid = this.id;
      this.packageRate.program=this.progId
      axios({
        method: "post",
        url: "/edit/program/rate",
        data: this.packageRate,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Package Edited Successully";
            this.showSnackBar = true;

            this.$router.go(-1);
            // Object.keys(this.programs).forEach(
            //   (key) => (this.programs[key] = null)
            // );
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + ", " + year + " ";
      return strTime;
    },
  },
};
</script>