<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-layout pt-2 wrap justify-start>
      <!-- <v-flex xs12 text-left>
        <span
          style="font-family: opensansbold; font-size: 14px; color: #808080"
        >
          Bookings
        </span>
      </v-flex> -->
      <v-flex xs12 sm6 lg10 text-left>
        <span
          style="font-family: opensansbold; font-size: 14px; color: #808080"
        >
          Bookings
        </span>
      </v-flex>
      <v-flex xs12 sm6 lg2 text-right>
        <v-select
          v-model="currentYear"
          :items="yearList"
          outlined
          dense
          color="#68D389"
          label="Year"
        ></v-select>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-end>
      <!-- <v-flex xs8 sm9 md9 lg9 xl10 text-left align-self-end>
        <v-layout wrap justify-center>
          <v-flex xs7 sm6>
            <span
              style="font-family: opensansbold; font-size: 22px; color: #1c1c1c"
            >
              ₹ 56
              ₹ {{ graphData.totalsale }}
            </span>
          </v-flex>
          <v-flex xs5 sm6 text-left align-self-end>
            <span
              style="font-family: opensansbold; font-size: 15px; color: #3dc131"
            >
              Current Bookings:
            </span>
            <span
              style="font-family: opensansbold; font-size: 15px; color: #3dc131"
            >
              66
              {{ graphData.transactions }}
            </span>
          </v-flex>
        </v-layout>
      </v-flex> -->
      <!-- <v-flex xs4 sm3 md3 lg3 xl2 text-right>
        <v-select
          outlined
          dense
          color="#f5f5f529"
          placeholder="Sort"
          v-model="sortItem"
          :items="sortItems"
          item-text="name"
          item-value="value"
          item-color="#70707029"
          hide-details
          class="analyticsSelector rounded-lg"
          v-on:input="getData"
        >
          <template v-slot:item="{ item }">
            <span
              class="text-left"
              style="font-family: opensansbold; font-size: 18px; color: #041c4b"
            >
              {{ item.name }}
            </span>
          </template>
          <template v-slot:selection="{ item }">
            <span
              style="font-family: opensansbold; font-size: 18px; color: #041c4b"
            >
              {{ item.name }}
            </span>
          </template>
        </v-select>
      </v-flex> -->
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex>
        <!-- <vue-element-loading
          :active="appLoading"
          :is-full-screen="false"
          background-color="#FFFFFF"
          color="#FF4343"
          spinner="bar-fade-scale"
        /> -->
        <apexchart
          width="100%"
          height="300px"
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      ServerError: false,
      sortItems: [{ name: "2021", value: "2021" }],
      sortItem: "2021",
      startYear:2021,
      currentYear:null,
      newData:[
         {
          name: "Jungle Safari",
          data: [100, 32, 50, 24, 100, 70, 46, 56, 50, 40, 50, 30],
        },
        {
          name: "Nature Camps",
          data: [32, 50, 24, 100, 56, 50, 40, 70, 46, 56, 50, 40],
        },
        {
          name: "Accomodation",
          data: [6, 56, 50, 100, 32, 50, 24, 100, 70, 46, 70, 46],
        },
        {
          name: "A Day in Parambikulam",
          data: [100, 32, 50, 24, 50, 100, 32, 50, 24, 100, 70, 46],
        },
        {
          name: "Trekking",
          data: [0,0,0,0,0,0,0,0,0,0,0,0],
        },
      ],
      
      yearList: [],
      // currentYear: null,
      fromDate: new Date(),
      series: [],

      graphData: {
        graph: [],
      },
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          title: {
            text: "(in Number)",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };
  },
  watch: {
    currentYear() {
      this.getData()
    }
  },
  beforeMount() {
    this.currentYear = this.fromDate.getFullYear();
    for(let i=this.startYear;i<=this.currentYear+2;i++){
      this.yearList.push(i)
    }
    this.getData();
  },
  // computed: {
  //   chartOptions: function () {
  //     return {
  //       chart: {
  //         id: "SellerAnalytics",
  //         toolbar: {
  //           show: false,
  //           tools: {
  //             download: true,
  //             selection: false,
  //             zoom: false,
  //             zoomin: false,
  //             zoomout: false,
  //             pan: false,
  //           },
  //         },
  //         sparkline: {
  //           enabled: false,
  //         },
  //       },

  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         columnWidth: "55%",
  //         endingShape: "rounded",
  //       },
  //     },
  //       xaxis: {
  //         categories: [
  //           "Jan",
  //           "Feb",
  //           "Mar",
  //           "Apr",
  //           "May",
  //           "Jun",
  //           "Jul",
  //           "Aug",
  //           "Sep",
  //           "Oct",
  //           "Nov",
  //           "Dec",
  //         ],
  //         tooltip: {
  //           enabled: false,
  //         },
  //       },
  //        dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       show: true,
  //       width: 1,
  //       colors: ["transparent"],
  //     },
  //       fill: {
  //         type: "gradient",
  //         gradient: {
  //           shadeIntensity: 1,
  //           opacityFrom: 0.7,
  //           opacityTo: 0.9,
  //           stops: [0, 90, 100],
  //           colorStops: [
  //             {
  //               offset: 0,
  //               color: "#069EFC",
  //               opacity: 1,
  //             },
  //             {
  //               offset: 100,
  //               color: "#14F4C9",
  //               opacity: 1,
  //             },
  //           ],
  //         },
  //       },

  //     };
  //   },
  // },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/dashboard/getgraph",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          year: this.currentYear,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.graphData = response.data.data;
            this.series=this.graphData
         
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>