<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap v-if="feedback">
          <v-flex xs12 align-self-center py-4 text-left>
            <span style="font-family: poppinsbold; font-size: 20px">Feedback List</span>
          </v-flex>
          <v-flex xs12 pt-6 v-if="feedback">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"><b>Ticket</b></th>
                    <th class="text-left"><b>Sent Date</b></th>
                    <th class="text-left"><b>comment</b></th>
                    <th class="text-left"><b>Actions</b></th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="height: 90px" v-for="(item, i) in feedback" :key="i">
                    <td class="text-left">
                      <span v-if="item.ticket">
                        <b>#{{ item.ticket.ticketNo }}</b></span>
                      <span v-else>
                        <b>#{{ item.ticket }}</b></span>
                    </td>
                    <td class="text-left">{{ formatDate(item.addeddate) }}</td>
                    <td class="text-left textr">{{ item.comment }}</td>
                    <td class="text-left">
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left v-if="item.ticket !== null">
                          <v-btn plain :to="'FeedbackView?feed=' +
                            item.ticket._id +
                            '&guest=' +
                            item.guest._id
                            ">
                            <v-icon color="green">mdi-eye</v-icon>
                          </v-btn>
                        </v-flex>
                        <v-flex xs12 align-self-center text-left v-else>
                          <v-btn plain>
                            <v-icon color="green">mdi-eye</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
          <v-flex v-else xs12 align-self-center text-left pa-5>
            <span style="font-family:poppinsregular">No Feedbacks found!.</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pageCount > 0">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
            v-model="currentPage" color="#68D389"></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from '../../../store'
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      feedback: null,
      currentPage: 1,
      pages: 0,
      pageCount: 0,
    };
  },
  watch: {
    currentPage() {
      store.commit("changeCurrentPage", this.currentPage);
      this.getData();
    },
  },
  beforeMount() {
    if (store.state.currentPage) {
      this.currentPage = store.state.currentPage;
      this.getData();

    } else this.getData();

  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "feedback/getlist",
        params: {
          status: "completed",
          page: this.currentPage,
          count: 20,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.data) {
            this.feedback = response.data.data;
            this.pages = response.data.pages;
            this.pageCount = response.data.count;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
  },
};
</script>
