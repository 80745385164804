<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-app id="inspire">
      <v-layout wrap justify-center class="login_page">
        <v-flex xs12 sm6 md4 lg3 px-2 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs112 xl12>
              <v-card tile flat>
                <v-layout wrap justify-center>
                  <v-flex xs12 px-8 pt-12 py-6>
                    <v-layout wrap justify-center>
                      <v-flex xs12 py-4>
                        <router-link to="/">
                          <span
                            style="
                              font-family: poppinsbold;
                              font-size: 20px;
                              cursor: pointer;
                              color: #545454;
                            "
                          >
                            Parambikulam Tiger Reserve
                          </span>
                        </router-link>
                      </v-flex>
                      <v-flex xs12 pt-4>
                        <span
                          style="
                            font-family: poppinsmedium;
                            font-size: 22px;
                            color: #090909;
                          "
                        >
                          Accept Request to Login
                        </span>
                      </v-flex>
                      <v-flex xs12 pt-4>
                        <v-layout wrap justify-center>
                          <v-flex xs12>
                            <v-img
                              src="./../../assets/icons/approval.png"
                              height="100px"
                              contain
                            >
                            </v-img>
                          </v-flex>
                          <v-flex xs12 pt-4 text-center>
                            <span class="itemKey">
                              A user requested you for supervising his apiary.
                              You can approve or reject that request according
                              to your convenience.
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs6 px-2 pt-8>
                        <v-btn
                          block
                          tile
                          color="#1FB436"
                          dark
                          :ripple="false"
                          depressed
                          @click="validateInput(true)"
                        >
                          <span
                            style="
                              font-family: poppinssemibold;
                              font-size: 12px;
                              text-transform: none;
                            "
                          >
                            Approve
                          </span>
                        </v-btn>
                      </v-flex>
                      <v-flex xs6 px-2 pt-8>
                        <v-btn
                          block
                          tile
                          color="#C62525"
                          dark
                          :ripple="false"
                          depressed
                          @click="validateInput(false)"
                        >
                          <span
                            style="
                              font-family: poppinssemibold;
                              font-size: 12px;
                              text-transform: none;
                            "
                          >
                            Reject
                          </span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      user: null,
    };
  },
  methods: {
    changeUser(item) {
      this.user = item;
    },
    validateInput(item) {
      this.login(item);
    },
    login(item) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/approveallocation",
        data: {
          approve: item,
        },
        headers: {
          "x-auth-token": this.storage.data.token,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (response.data.signingData) {
              store.commit("userType", response.data.signingData.role);
              store.commit("userData", response.data.signingData);
              store.commit("loginUser", response.data.signingData.token);
            } else {
              store.commit("userType", this.storage.data.role);
              store.commit("userData", this.storage.data);
              store.commit("loginUser", this.storage.data.token);
            }
          } else {
            this.msg = response.data;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>