<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#68D389"
        spinner="bar-fade-scale"
      />
      <ServerError v-if="ServerError" />
      <v-layout wrap justify-center pa-4>
        <v-flex xs12 text-center>
          <span style="font-family:poppinsbold; font-size: 18px">Notice</span>
        </v-flex>
        <v-flex xs12 pt-2 text-justify>
          <span
            id="acceptNotice"
            style="
              font-family:poppinslight;
              font-size: 16px;
              letter-spacing: 0.7px;
            "
          >
            Minimum Participants required for conducting this program is
            <b> {{ storage.minGuest }} </b>, for an amount of
            <b> INR {{ storage.minGuest * packageData.foreigner }}</b
            >. If the minimum slots are not filled up by the program date, you
            should pay an additional amount on arrival at the Reserve which shall
            extend up to a maximum of
            <b>
              INR
              {{
                (storage.minGuest - selectedSlot.bookedCount - 1) *
                packageData.foreigner
              }}</b
            >. if you fail to pay the amount, you will not be able to avail the program
            and amount paid online shall be non refundable.
          </span>
        </v-flex>
        <v-flex xs12 text-right pt-2>
          <span class="px-2">
            <v-btn
              small
              depressed
              dark
              color="#FF4444"
              @click="confirmation(false)"
            >
              Decline
            </v-btn>
          </span>
          <span class="px-2">
            <v-btn
              small
              depressed
              dark
              color="#53A874"
              @click="confirmation(true)"
            >
              Accept
            </v-btn>
          </span>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    props: ["storage", "selectedSlot"],
    data() {
      return {
        appLoading: false,
        ServerError: false,
        msg: null,
        packageData: {},
      };
    },
    beforeMount() {
      this.getData();
    },
    methods: {
      confirmation(status) {
        this.$emit("stepper", {
          dialog: false,
          accept: status,
        });
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/programme/get",
          params: {
            id: this.storage._id,
            date: new Date(),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.packageData = response.data.package;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>