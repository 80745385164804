<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#68D389"
        spinner="bar-fade-scale"
      />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left align-self-center>{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card outlined tile>
            <v-layout wrap justify-center>
              <v-flex xs12 text-center align-self-center pa-2 text-uppercase>
                <v-btn
                  :disabled="
                    this.months[this.thisMonth] == this.month ? true : false
                  "
                  text
                  small
                  :ripple="false"
                  color="grey darken-2"
                  @click="$refs.calendar.prev()"
                >
                  <v-icon> mdi-chevron-left </v-icon>
                </v-btn>
                <span
                  style="
                    font-family: poppinsregular;
                    font-size: 16px;
                    color: #000000;
                  "
                >
                  {{ month }} {{ year }}
                </span>
                <v-btn
                  :disabled="month == lastDate ? true : false"
                  text
                  small
                  :ripple="false"
                  color="grey darken-2"
                  @click="$refs.calendar.next()"
                >
                  <v-icon> mdi-chevron-right </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="bookingDate"
          color="primary"
          type="month"
          :events="holidays"
          event-color="deep-purple"
        >
          <template v-slot:day-label="{ day, date, weekday }">
            <v-layout
              wrap
              justify-center
              :style="{
                'background-color': bookingDate == date ? '#68D389' : '',
              }"
              @click="
                date >= today && dateExist(date) ? (bookingDate = date) : null
              "
              py-6
            >
              <v-flex
                xs12
                :style="{
                  color:
                    bookingDate == date
                      ? '#FFFFFF'
                      : date < today
                      ? '#F7F7F7'
                      : weekday == 0 || weekday == 6
                      ? '#673ab7'
                      : dateExist(date)
                      ? '#000000'
                      : '#FF0000',
                }"
                style="font-weight: 500"
              >
                {{ day }}
              </v-flex>
            </v-layout>
          </template>
          <!-- <template v-slot:event="{ event }">
            <v-card tile color="deep-purple" dark >
              <v-layout wrap justify-center>
                <v-flex xs12 text-center text-capitalize>
                  {{ event.name }}
                </v-flex>
              </v-layout>
            </v-card>
          </template> -->
        </v-calendar>
      </v-sheet>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data: () => ({
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      today: new Date(new Date().getTime() + 1000 * 3600 * 24), // This Day for checking availability
      thisMonth: Number(new Date().getMonth()), // This Month for checking availability
      bookingDate: new Date(new Date().getTime() + 1000 * 3600 * 24), // Date Selected for Booking
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      month: null, // Month used to display as heder for Calender
      year: null, // Year used to display as heder for Calender
      availableDates: [],
      holidays: [],
      lastDate: null,
    }),
    beforeMount() {
      if (Number(this.today.getHours()) >= 17) {
        this.today.setDate(this.today.getDate() + 1);
        this.today = new Date(this.today.setHours(0, 0));
      }
      if (Number(this.bookingDate.getHours()) >= 17) {
        this.bookingDate.setDate(this.bookingDate.getDate() + 1);
        this.bookingDate = new Date(this.bookingDate.setHours(0, 0));
      }
      this.today = this.formatDate(this.today);
      this.bookingDate = this.formatDate(this.bookingDate);
      this.getData();
    },
    watch: {
      bookingDate() {
        this.$emit("stepper", {
          date: this.bookingDate,
        });
        let date = new Date(this.bookingDate);
        let month = date.getMonth();
        this.month = this.months[month];
        this.formatDate(this.bookingDate);
      },
    },
    methods: {
      getData() {
        var myDate = new Date();
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/booking/getavailabledates",
          params: {
            start: myDate,
            programme: this.$route.query.type,
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.availableDates = response.data.data;
            this.availableDates = this.availableDates.map((item) =>
              this.formatDate(item)
            );
            this.holidays = response.data.holiday;
            this.lastDate = this.months[
              new Date(
                this.availableDates[this.availableDates.length - 1]
              ).getMonth()
            ];
            for (var i = 0; i < this.holidays.length; i++) {
              this.holidays[i].start = new Date(this.holidays[i].start);
              this.holidays[i].end = new Date(this.holidays[i].end);
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      dateExist(item) {
        var exist = this.availableDates.find((date) => {
          return date == item;
        });
        return exist;
      },
      monthExist() {
        console.log(this.existingMonths);
      },
      formatDate(item) {
        var dt = new Date(item);
        var day = dt.getDate();
        var month = dt.getMonth();
        this.year = dt.getFullYear();
        month = month + 1;
        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;
        var strTime = this.year + "-" + month + "-" + day;
        return strTime;
      },
    },
  };
  </script>
  <style >
  .v-event.v-event-start.v-event-end {
    width: 100% !important;
  }
  </style>