<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex xs12 text-left px-8>
        <span style="color: #000; font-size: 20px; font-family: poppinsbold"
          >Booking Report</span
        >
      </v-flex>
      <v-flex px-3 xs12 sm12 md12 lg12 xl12 text-center>
        <v-layout wrap justify-start>
          <v-flex xs12 sm6 md3 pa-5>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="onFromDateSelected"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md3 pa-5>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs12 sm6 md3 lg3 xl4 pa-5>
            <v-select
              v-model="selectedFilter"
              :items="dateFilter"
              item-text="title"
              item-value="value"
              outlined
              dense
              clearable
              label="Date"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 xl4 pa-5 align-self-start>
            <download-excel :data="json_data" :fields="json_fields">
              <span
                style="
                  font-size: 16px;
                  font-family: poppinssemibold;
                  color: #68d389;
                  cursor: pointer;
                "
              >
                Download Excel</span
              >
            </download-excel>
          </v-flex>
          <!-- <v-flex md3 v-if="booking.length > 0">
            <v-layout wrap justify-end pt-6>
              <v-flex xs12 sm6 md6 lg6 xl6>
                <a
                  target="_blank"
                  :href="
                    'https://api.parambikulam.org/booking/adminget
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    pdf?date=' +
                    date + '&programme=' +programme
                  "
                  class="downlink"
                >
                  <span
                    style="
                      font-size: 16px;
                      font-family: poppinssemibold;
                      color: #68D389;
                    "
                  >
                    Download Pdf</span
                  >
                </a>
              </v-flex>
            </v-layout>
          </v-flex> -->
        </v-layout>
        <v-layout wrap justify-center pt-4>
          <v-flex>
            <v-tabs
              fixed-tabs
              v-model="tabValue"
              color="#FDAC53"
              centered
              slider-color="yellow"
              hide-slider
            >
              <v-tab v-for="item in titleArray" :key="item.value">
                {{ item.title }}
              </v-tab>
            </v-tabs>
          </v-flex>
        </v-layout>
        <v-tabs-items v-model="tabValue">
          <!-- <template v-for="(item, index) in titleArray"> -->
          <v-tab-item>
            <ProgramData
              :booking="booking"
              :pages="pages"
              :count="count"
              @stepper="winStepper"
            />
          </v-tab-item>
          <!-- <v-tab-item>
        <CottageData :booking="booking"  :pages="pages" :count="count"  @stepper="winStepper"/>
      </v-tab-item> -->
          <!-- </template> -->
        </v-tabs-items>
        <!-- <v-layout wrap justify-start px-5>
          <v-flex v-if="booking.length > 0" xs12 pt-4 text-left>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >Bookings</span
            >
            <BookingData :booking="booking" :pages="pages" @stepper="winStepper"/>
          </v-flex>
          <v-flex v-else text-left pa-4>
            <span class="textField2"> No Booking Found !! </span>
          </v-flex>
        </v-layout> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import BookingData from "./bookingData";
import ProgramData from "./programTable";
// import CottageData from "./cottageTable";
// import Reservation from "./reservation";
export default {
  components: {
    // Reservation,
    // BookingData,
    ProgramData,
    // CottageData,
  },
  // props: ["dateProp"],
  data() {
    return {
      booking: [],
      reservationData: [],
      currentPage: 1,
      pages: 0,
      appLoading: false,
      programme: null,
      slotDetail: null,
      excel: [],
      programeData: [],
      details: [],
      slotDetails: [],
      guest: [],
      programs: [],
      slotItems: [],
      panel: [],
      disabled: false,
      ServerError: false,
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      count: 12,

      json_fields: {
        TicketNo: "ticket",
        "Program Name": "programme",
        "Booking Received Date": "bookingReceivedDate",
        Person: "personName",
        Email: "email",
        "Phone Number": "phone",
        "Booked Date": "bookedDate",
        "DOB" : "dob",
        "Gender" : "gender",
        "Guest Type" : "type",
        "Amount for Program(INR)": "programmeTotal",
        "Amount for Checkpost Entry(INR)": "entranceTicket",
        "Amount for Vehicle(INR)": "vehicle",
        "Amount settled to account(Total-PG)(INR)" : "amountToAccount",
        "Amount(Total)(INR)" : "amount",
        "Refund Amount(If cancelled)(INR)" : "refundAmount",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      headings: ["Ticket Number", "Paid Date", "Paid By", "Paid Amount"],
      tabValue: 0,
      titleArray: [
        { title: "Total PROGRAM BOOKING LIST", value: "Program" },
        // { title: "ACCOMODATION", value: "Accomodation" },
      ],
      selectedFilter: "",
      dateFilter: [
        { title: "Past month", value: "1" },
        { title: "Past three months", value: "2" },
        { title: "Past financial year", value: "3" },
        { title: "This month", value: "4" },
      ],
    };
  },
  watch: {
    tabValue() {
      if (this.tabValue == 0) this.getData();
      else if (this.tabValue == 1) this.getData();
    },
    fromDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      this.getExcel();
    },
    toDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      this.getExcel();
    },
    selectedFilter() {
      this.toDate = null;
      this.fromDate = null;
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      this.getExcel();
    },
    programme() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      // this.getExcel();
    },
    slotDetail() {
      this.getData();
      // this.getExcel();
    },
  },
  beforeMount() {
    // this.selectedFilter=this.dateFilter[0].title
    if (this.$route.query.dateProp != "dateWisePrograms") this.date = null;
    // this.getData();
    if (this.fromDate) {
      this.fromDate.setDate(this.fromDate.getDate() - 20);
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    // if (this.date) {
    //   this.date.setDate(this.date.getDate() + 1);
    //   this.date = this.date.toISOString().substr(0, 10);
    // }
  },
  methods: {

    onFromDateSelected() {
      if (this.fromDate) {
        const fromDateObj = new Date(this.fromDate);
        const toDateObj = new Date(fromDateObj.setMonth(fromDateObj.getMonth() + 1));
        this.toDate = toDateObj.toISOString().slice(0, 10);
      }
    },
    // checkDate1() {
    //   // let ndate = new Date().toISOString().substr(0, 10);
    //   // if (this.fromDate == ndate) {
    //   const tomorrow = new Date(this.fromDate);
    //   tomorrow.setDate(tomorrow.getDate() + 30);
    //   this.toDate = tomorrow.toISOString().substr(0, 10);
    //   // }
    //   this.getData();
    // },

    winStepper(windowData) {
      if (windowData.getData) {
        this.currentPage = windowData.currentPage;
        this.pages = windowData.pages;
        this.getData();
      }
    },
    getData() {
      // console.log("send",this.currentPage)
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/booking/newbookingreport",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate,
          to: this.toDate,
          filter: this.selectedFilter,
          programme: this.programme,
          slotDetail: this.slotDetail,
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.booking = response.data.data;
            this.reservationData = response.data.reservations;
            this.pages = response.data.pages;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/booking/newbookingreport/programme/excel",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate,
          to: this.toDate,
          filter: this.selectedFilter,
        },
      })
        .then((response) => {
          if (response.data.status) {
            for (var i = 0; i < response.data.data.length; i++) {
              response.data.data[i].phone = JSON.stringify(
                response.data.data[i].phone
              );
              // response.data.data[i].type = JSON.stringify(
              //   response.data.data[i].type
              // );
            }
            this.excel = response.data.data;
            this.json_data = this.excel;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      // var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
<style scoped>
.itemTags {
  font-family: poppinsregular;
}
.itemHeading {
  color: #8d8d8d;
  font-size: 14px;
  font-family: poppinsregular;
}
</style>