<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end>
      <v-flex lg11 xl11 text-left>
        <v-card color="#F7F7F7" tile flat>
          <v-layout wrap justify-center pa-4>
            <v-flex xs12 pt-6>
              <span class="itemHeading2"> ACCOMODATION SUMMARY </span>
            </v-flex>
            <v-flex xs12 pt-2>
              <span class="itemTags" style="color: #9e9e9e"> Package </span>
            </v-flex>
            <v-flex xs12 pt-2>
              <span class="tableValue1">
                {{ $route.query.name }}
              </span>
              <span
                style="
                  font-family: poppinssemibold;
                  font-size: 14px;
                  color: #414141;
                "
              >
                {{ $route.query.caption }}
              </span>
            </v-flex>
            <v-flex xs12 pt-4>
              <span class="itemTags" style="color: #9e9e9e">
                Selected Date
              </span>
            </v-flex>
            <v-flex xs12 md12 align-self-center>
              <span class="itemHeading2"> {{ formatDate(date) }} </span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center px-4 pb-4 v-if="date">
            <v-flex xs12>
              <span class="itemTags" style="color: #9e9e9e">
                Availability
              </span>
            </v-flex>
            <v-flex xs12>
              <v-card
                v-for="item in storage"
                :key="item._id"
                flat
                :ripple="false"
                class="my-2"
                color="#68D389"
              >
                <v-layout wrap justify-center pa-6>
                  <v-flex xs12>
                    <span class="tableValue1" style="color: #ffffff">
                      <span v-if="item.onlineAvailable != 0">
                        {{ item.onlineAvailable }}
                      </span>
                      <span v-else> No </span>
                      Rooms Available
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 py-4>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12>
              <span
                class="itemTags"
                style="color: #000000"
                v-if="storage.length < 1"
              >
                No Rooms Available on {{ formatDate(date) }}
              </span>
            </v-flex>
            <v-flex xs12 v-if="selectedSlot.onlineAvailable > 0">
              <v-layout wrap>
                <v-flex xs12 py-4>
                  <v-text-field
                    class="textField2"
                    dense
                    type="number"
                    v-model="numberOfRooms"
                    outlined
                    label="No. of rooms required"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 py-4>
                  <v-text-field
                    class="textField2"
                    dense
                    v-model="reservedFor"
                    outlined
                    label="Reserved for (required)"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 py-4>
                  <v-btn
                    block
                    dark
                    depressed
                    color="#68D389"
                    :ripple="false"
                    @click="validateInput"
                  >
                    Submit
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- <template v-if="selectedSlot.onlineAvailable > 0">
              <v-flex xs12 py-4>
                <v-text-field
                  class="textField2"
                  dense
                  type="number"
                  v-model="numberOfRooms"
                  outlined
                  label="No. of rooms required"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 py-4>
                <v-text-field
                  class="textField2"
                  dense
                  v-model="reservedFor"
                  outlined
                  label="Reserved for (required)"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 py-4>
                <v-btn
                  block
                  dark
                  depressed
                  color="#68D389"
                  :ripple="false"
                  @click="validateInput"
                >
                  Submit
                </v-btn>
              </v-flex>
            </template> -->
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["date", "storage", "programme", "programData"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      selectedSlot: {},
      guest: [],
      numberOfRooms: null,
      reservedFor: null,
    };
  },
  created() {
    if (this.storage[0].status == "Active") {
      this.selectedSlot = this.storage[0];
    }
  },
  watch: {
    storage: {
      handler(val) {
        if (val[0].status == "Active") {
          this.selectedSlot = val[0];
        }
      },
      deep: true,
    },
    numberOfRooms() {
      if (this.numberOfRooms > this.selectedSlot.onlineAvailable) {
        this.msg =
          "Please select maximum of " +
          this.selectedSlot.onlineAvailable +
          " rooms";
        this.numberOfRooms = null;
        this.showSnackBar = true;
      }
    },
  },
  methods: {
    validateInput() {
      if (this.numberOfRooms > this.selectedSlot.onlineAvailable) {
        this.msg =
          "Please select maximum of " +
          this.selectedSlot.onlineAvailable +
          " rooms";
        this.showSnackBar = true;
      } else if (!this.reservedFor) {
        this.msg = "Please provide the guest name";
        this.showSnackBar = true;
      } else this.addToCart();
    },

    addToCart() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/reserve/add",
        data: {
          programme: this.$route.query.type,
          bookingDate: this.date,
          slotDetail: this.selectedSlot._id,
          numberOfRooms: Number(this.numberOfRooms),
          reservedFor: this.reservedFor,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.numberOfRooms = null;
            this.reservedFor = null;

            // this.$router.push("/program/" + this.$route.query.type);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            if (response.data.expired) {
              this.$router.push("/program/" + this.$route.query.type);
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
<style>
.v-application .primary--text {
  color: #68d389 !important;
  caret-color: #68d389 !important;
}

#slotInput {
  text-align: center;
}
</style>
