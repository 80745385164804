<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap pa-3>
      <v-flex xs9 text-left>
        <span style="font-family: poppinsbold; font-size: 20px"
          >Purchase Details</span
        >
      </v-flex>
           <v-flex md3 v-if="purchaseData.length > 0 && header != 'Purchase'">
             <a
                  target="_blank"
                  :href="
                    'https://api.parambikulam.org/purchase/getReportPdf'
                  "
                  download
                  class="downlink"
                >
                  <span
                    style="
                      font-size: 16px;
                      font-family: poppinssemibold;
                      color: #68d389;
                    "
                  >
                    Download Report</span
                  >
                </a>
          </v-flex>
     
    </v-layout>
    <v-layout wrap justify-start>
          <v-flex xs12 sm6 md3 pa-5>
            <!-- <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="menu1 = false"
              
              >
              
              </v-date-picker>
            </v-menu> -->
          </v-flex>
             <v-flex xs12 sm6 md3 pa-5>
            <!-- <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="menu2 = false"
              
              >
              
              </v-date-picker>
            </v-menu> -->
          </v-flex>
    
       
        </v-layout>

    <v-layout pt-6   wrap justify-center>
      <v-flex md12>
        <v-layout wrap>
          <v-flex
            v-for="item in titleArray"
            text-center
            @click="header = item.value"
            style="cursor: pointer"
            :key="item.value"
          >
            <span
              class="text-uppercase"
              style="font-family: poppinssemibold; letter-spacing: 0.6px"
            >
              {{ item.title }}</span
            >
            <v-progress-linear
              v-if="header == item.value"
              :value="100"
              color="#68D389"
              height="2"
            ></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5>
          <v-flex md12 :key="header" v-if="header == 'Pending'">
            <PurchaseDetails
              :status="header"
              :storage="purchaseData"
              @stepper="winStepper"
            />
          </v-flex>
          <v-flex md12 :key="header" v-if="header == 'Shipped'">
            <PurchaseDetails
              :status="header"
              :storage="purchaseData"
              @stepper="winStepper"
            />
          </v-flex>
          <v-flex md12 :key="header" v-if="header == 'Delivered'">
            <PurchaseDetails
              :status="header"
              :storage="purchaseData"
              @stepper="winStepper"
            />
          </v-flex>
          <v-flex md12 :key="header" v-if="header == 'Cancelled'">
            <PurchaseDetails
              :status="header"
              :storage="purchaseData"
              @stepper="winStepper"
            />
          </v-flex>
          <v-flex md12 :key="header" v-if="header == 'Purchase'">
            <PurchaseTable
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import PurchaseDetails from "./purchaseDetails";
import PurchaseTable from "./purchaseTable";
export default {
  components: {
    PurchaseDetails,
    PurchaseTable
  },
  data() {
    return {
      appLoading: false,
      panel: [],
      disabled: false,
      ServerError: false,
      fromDate: null,
      toDate: null,
      menu1: false,
      menu2: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      tabValue: 0,
      header: "Pending",
      titleArray: [
        { title: "PENDING", value: "Pending" },
        { title: "SHIPPED", value: "Shipped" },
        { title: "DELIVERED", value: "Delivered" },
        { title: "CANCELLED", value: "Cancelled" },
        { title: "REPORTS", value: "Purchase" },

        // { title: "COTTAGES", value: "cottage" },
      ],
      purchaseData: [],
    };
  },

  beforeMount() {
    // this.getData();
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    header() {
      if(this.header != 'Purchase')
      this.getData();
    },
  },
  methods: {
    winStepper(windowData) {
      if (windowData.status) {
        if (windowData.getData) {
          this.header = windowData.deliveryStatus;
          this.getData();
        }
      } else {
        this.showSnackBar = true;
        this.msg = windowData.msg;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          status: this.header,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.purchaseData = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>