<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center pb-4 px-3>
      <v-flex v-if="!isProgramAdded" xs12 text-left>
        <span class="itemHeading"> Add New Program </span>
      </v-flex>
      <v-flex v-else xs12 text-left pb-4>
        <span v-if="!slotAddition" class="itemHeading">
          Add New Package Rate
        </span>
        <span v-else class="itemHeading"> Add New Slot </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout v-if="!isProgramAdded" wrap>
          <v-flex xs12 md12>
            <v-layout wrap>
              <v-flex xs12 md8>
                <v-text-field
                  class="textField2"
                  dense
                  v-model="programs.progName"
                  outlined
                  label="Title of the Program"
                  required
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md4 pl-md-4 pl-lg-3 pl-xl-3>
                <v-select
                  :items="categories"
                  label="Category"
                  dense
                  v-model="programs.category"
                  item-value="_id"
                  item-text="name"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  class="textField2"
                  dense
                  rows="3"
                  v-model="programs.caption"
                  outlined
                  label="Caption of the Program"
                  required
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md12 xs12>
            <ImageComp
              @stepper="winStepper"
               :height="'800'"
              :width="'600'"
              :heading="'Upload Images'"
            />
          </v-flex>
          <v-flex xs12 pt-5>
            <CoverImageComp
              @stepper="winStepper"
              :height="'2118'"
              :width="'8001'"
              :heading="'Upload Cover Image'"
               :componentType="'coverImage'"
            />
          </v-flex>

          <v-flex xs12 md12>
            <v-layout wrap justify-center>
              <v-flex xs12 md12 pt-5 text-left>
                <span class="textField1"
                  >Please Add the Program Description</span
                >
                <vue-editor
                  class="textField2 pt-1"
                  v-model="programs.description"
                ></vue-editor>
              </v-flex>
              <v-flex xs12 md12 pt-5 text-left>
                <span class="textField1">Please Add the Program Notes</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="programs.notes"
                ></vue-editor>
              </v-flex>
              <v-flex xs12 md12 text-left pt-5>
                <span class="textField1">Please Add the Program Rules</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="programs.rules"
                ></vue-editor>
              </v-flex>
              <v-flex xs12 md12 text-left pt-5 v-if="programs.isCottage">
                <span class="textField1">Please Add the Program Schedule</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="programs.cottage.schedule"
                ></vue-editor>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 v-if="programs.isCottage">
            <v-combobox
              v-model="programs.cottage.activities"
              :items="items"
              chips
              clearable
              label="Activities"
              multiple
              solo
              class="pt-3"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item }}</strong
                  >&nbsp;
                </v-chip>
              </template>
            </v-combobox>
          </v-flex>
          <v-flex xs12 md12 pt-4>
            <v-layout wrap>
              <v-flex xs12 md6 text-left>
                <span class="textField1"
                  >Please Select the types of guests you want to include.</span
                >
                <v-layout wrap justify-center pt-5 text-left>
                  <v-flex xs12 md4>
                    <v-checkbox
                      v-model="programs.bookingAvailability.indian"
                      label="Indian"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 md4 px-md-3 px-lg-3 px-xl-3>
                    <v-checkbox
                      v-model="programs.bookingAvailability.foreigner"
                      label="Foreigner"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-checkbox
                      v-model="programs.bookingAvailability.children"
                      label="Children"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12 md12 pt-4>
                <v-layout wrap justify-center text-left>
                  <v-flex xs12 md4>
                    <v-text-field
                      class="textField2"
                      dense
                      v-model="programs.startPoint"
                      outlined
                      label=" Please specify Starting Location"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4 px-md-3 px-lg-3 px-xl-3>
                    <v-text-field
                      class="textField2"
                      dense
                      v-model="programs.endPoint"
                      outlined
                      label="Please specify Ending Location"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      class="textField2"
                      dense
                      v-model="programs.reportingTime"
                      outlined
                      label="Please specify Reporting Time"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 md12>
                <v-layout wrap justify-center text-left>
                  <v-flex xs12 md4>
                    <v-text-field
                      class="textField2"
                      type="number"
                      dense
                      v-model="programs.duration"
                      outlined
                      label="Please specify Duration (in Hrs)"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4 px-md-3 px-lg-3 px-xl-3>
                    <v-text-field
                      class="textField2"
                      dense
                      type="text"
                      v-model="programs.onlinePercent"
                      outlined
                      label="Please specify Online Percent (in %)"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.minGuest"
                      outlined
                      label="Please specify Minimum Guest"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 md12>
                <v-layout wrap justify-center text-left>
                  <v-flex xs12 md4>
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.maxGuest"
                      outlined
                      label="Please specify Maximum Guest"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4 px-md-3 px-lg-3 px-xl-3>
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.minAge"
                      outlined
                      label="Please specify Minimum Age"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.maxAge"
                      outlined
                      label="Please specify Maximum Age"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  color="#68D389"
                  light
                  :ripple="false"
                  depressed
                  @click="validateInput"
                  class="itemValue"
                >
                  <span style="color: #ffffff">Save</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout v-else wrap justify-center>
          <v-flex xs12 sm12>
            <AddPackage
              v-if="!slotAddition"
              :programId="programId"
              @stepper="winStepper"
            />
          </v-flex>
          <v-flex xs12 sm12>
            <AddSlot
              v-if="slotAddition"
              :programId="programId"
              @stepper="winStepper"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- <v-flex v-if="isProgramAdded" md4 pr-md-3 pr-lg-3 pr-xl-3>
        <ProgramSummary />
    
      </v-flex> -->
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import ImageComp from "@/components/CommonComponents/multipleImages";
import CoverImageComp from "@/components/CommonComponents/singleImage";
import AddSlot from "./Slots/addSlot";
import AddPackage from "./Package/addPackage";
export default {
  components: {
    ImageComp,
    CoverImageComp,
    AddSlot,
    AddPackage,
  },
  props: ["isCottage", "progName"],
  data() {
    return {
      programs: {
        progName: null,
        description: null,
        caption: null,
        startPoint: null,
        endPoint: null,
        duration: null,
        onlinePercent: null,
        isCottage: false,
        minGuest: null,
        maxGuest: null,
        maxAge: null,
        minAge: null,
        reportingTime: null,
        rules: null,
        notes: null,
        addons: null,
        category: null,
        bookingAvailability: {
          indian: true,
          foreigner: true,
          children: true,
        },
      },
      programId: null,
      isProgramAdded: false,
      valid: false,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      slotAddition: false,
      packageRate: [],
      selectedImage: null,
      categories: [],
      coverImageFile: null,
      items: ["Accommodation", "Safari", "Trek/Birdwatching", "Bamboo Rafting"],
      imageFiles: [],
    };
  },
  beforeMount() {
    this.programs.isCottage = this.isCottage;
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "AddPackage") {
        this.slotAddition = window_data.slotAddition;
        this.packageRate = window_data.response;
      } else if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
      } else if (window_data.type == "coverImage") {
        this.coverImageFile = window_data.selectedFiles;
      }
    },
    validateInput() {
      if (!this.programs.progName) {
        this.msg = "Please Provide Program name";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.description) {
        this.msg = "Please Provide Description";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.rules) {
        this.msg = "Please Provide Program rules";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.notes) {
        this.msg = "Please Provide Program notes";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.startPoint) {
        this.msg = "Please Provide start point of the program";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.endPoint) {
        this.msg = "Please Provide end point of the program";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.reportingTime) {
        this.msg = "Please Provide Reporting time";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.duration) {
        this.msg = "Please Provide Program duration";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.onlinePercent) {
        this.msg = "Please Provide Online percent";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.minGuest) {
        this.msg = "Please Provide Minimum guest";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.maxGuest) {
        this.msg = "Please Provide Maximum guest";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.minAge) {
        this.msg = "Please Provide Minimum age";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.maxAge) {
        this.msg = "Please Provide Maximum age";
        this.showSnackBar = true;
        return;
      } else if (!this.imageFiles.length >1) {
        this.msg = "Please Upload Image";
        this.showSnackBar = true;
        return;
      } else if (!this.coverImageFile) {
        this.msg = "Please Upload Cover Image";
        this.showSnackBar = true;
        return;
      } else {
        this.addProgram();
      }
    },
    getCategories() {
      axios({
        method: "GET",
        url: "/category/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.categories = response.data.data;
            for (var i = 0; i < this.categories.length; i++) {
              if (this.categories[i].name == "Accomodations") {
                if (!this.isCottage) this.categories.splice(i, 1);
              } else {
                if (this.isCottage) this.categories.splice();
              }
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    remove(item) {
      this.programs.cottage.activities.splice(
        this.programs.cottage.activities.indexOf(item),
        1
      );
      this.programs.cottage.activities = [...this.programs.cottage.activities];
    },
    addProgram() {
      axios({
        method: "post",
        url: "/add/program",
        data: this.programs,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Program Added Successully";
            this.programId = response.data.data._id;
            Object.keys(this.programs).forEach(
              (key) => (this.programs[key] = null)
            );
            if (this.imageArray.length > 0) {
              for (var i = 0; i < this.imageFiles.length; i++)
                this.formData.append("images", this.imageFiles[i]);
              this.uploadMainImages(this.programId);
            }
            if (this.coverImageFile) {
              this.uploadCoverImages(this.programId);
            }
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadMainImages(item) {
      this.appLoading = true;
      this.formData.append("programme", item);
      axios({
        method: "POST",
        url: "/programme/images",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.isProgramAdded = true;
          } else {
            this.msg = "Can't Upload Image.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showSnackBar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadCoverImages(item) {
      this.appLoading = true;
      this.formDataCover.append("programme", item);
      this.formDataCover.append("image", this.coverImageFile);
      axios({
        method: "POST",
        url: "/programme/UploadCoverImage",
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.isProgramAdded = true;
          } else {
            this.msg = "Can't Upload Cover Image.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}
.v-application .primary {
  background-color: red !important;
}
</style>