<template>
  <div>
    <v-layout v-for="(item, i) in reservationData" :key="i" wrap>
      <v-flex xs12 md12 pt-4>
        <v-card outlined>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap pa-2>
                <v-flex xs4 lg2>
                  <v-img
                    v-if="item.slotDetail.slot.programme.photos.length > 0"
                    width="175px"
                    height="113px"
                    :src="mediaURL + item.slotDetail.slot.programme.photos[0]"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                  <v-img
                    v-else
                    width="175px"
                    height="113px"
                    src="require('../../assets/images/no-image.png')"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                </v-flex>
                <v-flex xs8 lg10 pa-2>
                  <v-layout wrap justify-start>
                    <v-flex xs12 text-left>
                      <span class="itemCaption" style="font-size: 18px">
                        {{ item.slotDetail.slot.programme.progName }}
                      </span>
                    </v-flex>
                    <v-flex xs12 md6 text-left py-2 v-if="item.addedBy">
                      <span class="itemCaption" style="font-size: 16px">Added By : </span>
                      <span class="itemCaption" style="font-size: 16px">
                        {{ item.addedBy.email }} <span v-if="item.addedBy.details.name"> ({{ item.addedBy.details.name }}) </span>
                      </span>
                    </v-flex>
                    <v-flex xs12 md6 text-left py-2 v-if="item.addedBy">
                      <span class="itemCaption" style="font-size: 16px">Phone : </span>
                      <span class="itemCaption" style="font-size: 16px">
                        {{ item.addedBy.phone }}
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md3 text-left>
                      <span class="textField2"> Selected Date</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px"
                        >{{ formatDate(item.bookingDate) }}
                      </span>
                    </v-flex>

                    <v-flex md3 text-left>
                      <span class="textField2"> Selected Slot</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ formatTime(item.slotDetail.startTime) }} -
                        {{ formatTime(item.slotDetail.endTime) }}
                      </span>
                    </v-flex>

                    <v-flex md3 text-left>
                      <span
                        v-if="item.slotDetail.slot.programme.isCottage"
                        class="textField2"
                      >
                        Rooms Reserved</span
                      >
                      <span v-else class="textField2"> Seats Reserved</span
                      ><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ item.reserved }}
                      </span>
                    </v-flex>
                    
                    <v-flex md3 text-left v-if="item.reservedFor">
                      
                      <span  class="textField2"> Reserved for</span
                      ><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ item.reservedFor }}
                      </span>
                    </v-flex>
                  </v-layout>

                  <v-layout wrap justify-start>
                    <v-flex xs12 align-self-end text-right>
                      <v-btn
                        plain
                        class="text-capitalize"
                        @click="getOrder(item._id)"
                        color="red"
                        >Cancel Reservation</v-btn
                      ></v-flex
                    >
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>

    <v-dialog persistent v-model="itemdelete" max-width="600px">
      <v-card>
        <v-card-title style="font-family:poppinssemibold;font-size:16px"
          >Are you sure you want to delete this Reservation?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="itemdelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItem(reservId)"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import ImageLoader from "@/components/Common/imageLoader";
export default {
  props: ["reservationData", "pages"],
  components: {
    ImageLoader,
  },
  data() {
    return {
      currentPage: 1,
      itemdelete: false,
      reservId: "",
    };
  },
  watch: {
    currentPage() {
      this.winStepper();
    },
  },
  methods: {
    winStepper() {
      console.log("step", this.currentPage);
      this.$emit("stepper", {
        getData: true,
        currentPage: this.currentPage,
        pages: this.pages,
      });
    },
    getOrder(item) {
      this.reservId = item;
      this.itemdelete = true;
    },
    deleteItem(val) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/booking/adminreservation/remove",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: val,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.itemdelete = false;
          if (response.data.status) {
            this.winStepper();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>