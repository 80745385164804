<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389  "
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap pt-10 justify-start>
      <v-flex xs6 sm6 md8 text-uppercase align-self-center>
        <span class="itemHeading"> REPORTS </span>
      </v-flex>
      <v-flex xs6 sm6 md2 lg2 xl2 align-self-center>
        <v-dialog v-model="dialog" scrollable max-width="40%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#68D389" dark v-bind="attrs" v-on="on">
              Add Report
            </v-btn>
          </template>
          <v-card class="pa-4" height="100%">
            <v-layout wrap>
              <v-flex xs12 text-uppercase align-self-center>
                <span class="itemHeading"> ADD REPORT </span>
              </v-flex>
              <v-flex xs12 py-4 v-if="flag">
                <v-file-input
                  hide-details
                  outlined
                  dense
                  show-size
                  label="Please upload the report"
                  @change="selectFile"
                ></v-file-input>
                <!-- <template v-if="stopLoader">
                    <v-progress-linear
                      color="#68D389"
                      v-model="calc"
                      height="20"
                    >
                      <template v-slot:default="{ value }">
                        <strong>{{ Math.ceil(value) }}%</strong>
                      </template></v-progress-linear
                    >
                </template> -->
              </v-flex>
              <v-flex xs12 py-4>
                <v-text-field
                  class="textField2"
                  dense
                  type="year"
                  prepend-icon="mdi-calendar"
                  v-model="year"
                  outlined
                  label="Year"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex md12 xs12 v-if="flag">
                <ImageComp
                  @stepper="winStepper"
                  :height="'1'"
                  :width="'1'"
                  :heading="'Upload Thumbnail Image'"
                  :componentType="'thumbnail'"
                />
              </v-flex>
              <v-flex md12 pt-4>
                <v-layout justify-end>
                  <v-flex md3>
                    <v-btn
                      block
                      tile
                      dark
                      color="#68D389"
                      :disabled="!selectedFile"
                      light
                      :ripple="false"
                      depressed
                      @click="validateInput"
                      class="itemValue"
                    >
                      <span>Save </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
      <v-flex xs6 sm6 md2 lg2 xl2 align-self-center>
        <v-dialog v-model="topImageDialog" v-if="imgFlag" scrollable max-width="60%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#68D389" dark v-bind="attrs" v-on="on">
              <template v-if="staticData">
                <v-layout wrap v-if="staticData.topimage">
                  <v-flex v-if="staticData.topimage.length > 0">
                    <span v-if="staticData.topimage">Edit Top Image</span>
                  </v-flex>
                </v-layout>
                <v-layout wrap v-else>
                  <v-flex> <span>Add Top Image</span> </v-flex>
                </v-layout>
              </template>
              <!-- <template v-else>
              </template> -->
            </v-btn>
          </template>
          <v-card class="pa-4" height="100%">
            <v-layout wrap justify-center>
              <v-flex v-if="staticData.topimage" xs12 md12>
                <ImageComp
                  @stepper="winStepper"
                  :singleImage="staticData.topimage"
                  :pageId="staticData._id"
                  :height="'2118'"
                  :width="'8001'"
                  :heading="'Upload Top Image'"
                  :componentType="'topImage'"
                />
              </v-flex>
              <v-flex v-else xs12 md12>
                <ImageComp
                  @stepper="winStepper"
                  :singleImage="topimage"
                  :height="'2118'"
                  :width="'8001'"
                  :heading="'Upload Top Image'"
                  :componentType="'topImage'"
                />
              </v-flex>
              <v-flex xs2 pt-4 px-2>
                <v-btn
                  small
                  class="mr-2"
                  block
                  color="success"
                  @click="validateTopImage"
                >
                  <span class="itemArrow"> Submit</span>
                </v-btn>
              </v-flex>
              <v-flex xs2 pt-4 px-2>
                <v-btn
                  small
                  class="mr-2"
                  block
                  color="red"
                  @click="topImageDialog = false"
                >
                  <span class="itemArrow"> Cancel</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-start pt-4>
      <v-flex xs12 sm6 md3 xl4 v-for="(item, i) in reportData" :key="i" pa-4>
        <v-card tile>
          <v-layout wrap justify-center pb-2>
            <v-flex xs12>
              <a
                target="_blank"
                download=""
                :href="'https://api.parambikulam.org/u/' + item.file"
                class="downlink"
              >
                <v-img cover v-if="item.image" :src="mediaURL + item.image">
                  <template v-slot:placeholder>
                    <ImageLoader />
                  </template>
                </v-img>
              </a>
            </v-flex>
            <v-flex xs12 pt-2>
              <span
                class="slotHeading"
                style="
                  line-height: 1.5em;
                  min-height: 4em;
                  float: left;
                  width: 100%;
                "
              >
                {{ item.year }}
              </span>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap justify-center pt-2>
                <v-flex xs6 pa-4>
                  <v-btn
                    small
                    class="mr-2"
                    outlined
                    block
                    color="success"
                    @click="editSlider(item)"
                  >
                    Edit
                  </v-btn>
                </v-flex>
                <v-flex xs6 pa-4>
                  <v-dialog v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: poppinsmedium"
                        class="mr-2"
                        outlined
                        block
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                      >
                        Delete
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        report?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="editdialog" scrollable max-width="70%">
      <v-card class="pa-4" height="100%">
        <v-layout wrap>
          <template v-if="progressBar">
            <v-progress-linear
              striped
              color="#68D389"
              v-model="calc"
              height="14"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template></v-progress-linear
            >
          </template>
          <v-flex xs12 py-4>
            <v-file-input
              hide-details
              outlined
              dense
              show-size
              v-model="editingitem.file"
              :label="'Change uploaded report:' + editingitem.file"
              @change="selectFileEdit"
            ></v-file-input>
          </v-flex>
          <v-flex
            xs12
            sm8
            md5
            lg3
            py-4
            v-if="selectedFile !== null && editingitem._id"
          >
            <v-btn
              block
              tile
              dark
              color="#68D389"
              light
              :ripple="false"
              depressed
              @click="uploadDocumentEdit(editingitem._id)"
              class="itemValue"
            >
              <span>Upload Document</span>
            </v-btn>
          </v-flex>
          <v-flex xs12 py-4>
            <v-text-field
              class="textField2"
              dense
              type="year"
              prepend-icon="mdi-calendar"
              v-model="editingitem.year"
              outlined
              label="Year"
              required
            ></v-text-field>
          </v-flex>
          <v-flex md12 xs12>
            <ImageComp
              :singleImage="editingitem.image"
              :pageId="editingitem._id"
              @stepper="winStepper"
              :height="'500'"
              :width="'500'"
              :heading="'Upload Thumbnail Image'"
              :componentType="'thumbnail'"
            />
          </v-flex>
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3 px-2>
                <v-btn
                  block
                  tile
                  dark
                  color="red"
                  light
                  :ripple="false"
                  depressed
                  @click="dialogclose()"
                  class="itemValue"
                >
                  <span>close</span>
                </v-btn>
              </v-flex>
              <v-flex md3 px-2>
                <v-btn
                  block
                  tile
                  dark
                  color="#68D389"
                  light
                  :ripple="false"
                  depressed
                  @click="edit(editingitem._id)"
                  class="itemValue"
                >
                  <span>Save Changes</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ImageComp from "@/components/CommonComponents/singleImage";
import ImageLoader from "@/components/Common/imageLoader";
import axios from "axios";
export default {
  components: {
    ImageComp,
    ImageLoader,
  },
  data() {
    return {
      progressBar: false,
      reportData: [],
      Text: null,
      id: null,
      valid: false,
      imageArray: [],
      editingitem: [],
      editdialog: false,
      formData: new FormData(),
      formData1: new FormData(),
      imgId: null,
      topImage: null,
      staticData: [],
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dialogm1: "",
      dialog: false,
      topImageDialog: false,
      thumbnail: null,
      selectedFile: null,
      year: null,
      reportId: null,
      flag: true,
      skill: 0,
      skillFlag: false,
      stopLoader: false,
      nos: 0,
      calc: 0,
      imgFlag:false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    selectFile(file) {
      //   this.progress = 0;
      this.stopLoader = true;
      this.selectedFile = file;
      console.log(file);
      console.log((file.size / 3600) * 100);
      for (let i = 0; i <= (file.size / 3600) * 100; i++) {
        this.nos = i;
        this.stopLoader = false;
      }
    },
    selectFileEdit(file) {
      this.selectedFile = file;
      // this.uploadDocumentEdit(id);
    },
    winStepper(window_data) {
      if (window_data.type == "thumbnail") {
        this.thumbnail = window_data.selectedFiles;
      }
      if (window_data.type == "topImage") {
        this.topImage = window_data.selectedFiles;
      }
    },
    validateTopImage() {
      if (!this.topImage) {
        this.msg = "Please Upload Top Image";
        this.showSnackBar = true;
        return;
      } else this.uploadTopImage();
    },
    validateInput() {
      if (!this.thumbnail) {
        this.msg = "Please Upload Thumbnail";
        this.showSnackBar = true;
        return;
      } else if (!this.selectedFile) {
        this.msg = "Please Upload Report";
        this.showSnackBar = true;
        return;
      } else {
        this.addReport();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/annualreport/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.status) {
            this.reportData = response.data.data;
            this.staticData = response.data.staticData;
            this.imgFlag=true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addReport() {
      axios({
        method: "post",
        url: "/annualreport/add",
        data: {
          year: this.year,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.flag = false;
            this.year = null;
            this.reportId = response.data.id;

            this.uploadDocument(this.reportId);
          } else {
            axios({
              url: "/annualreport/remove",
              method: "POST",
              data: {
                id: response.data.id,
              },
              headers: {
                token: localStorage.getItem("token"),
              },
            });
            this.msg = "Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadDocument(id) {
      this.appLoading = true;
      this.formData1.append("id", id);
      this.formData1.append("doc", this.selectedFile);

      axios({
        method: "POST",
        url: "/annualreport/doc",
        data: this.formData1,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.clearAll();
            if (this.thumbnail) {
              this.uploadImage(this.reportId);
            }
            this.getData();
            this.msg = "Added Successfully";
            this.flag = true;
            this.selectedFile = null;
            this.dialog = false;
          } else {
            axios({
              url: "/annualreport/remove",
              method: "POST",
              data: {
                id: response.data.id,
              },
              headers: {
                token: localStorage.getItem("token"),
              },
            });
            this.msg = "Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showsnackbar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadImage(id) {
      this.appLoading = true;
      this.formData.append("image", this.thumbnail);
      this.formData.append("id", id);
      axios({
        method: "POST",
        url: "/annualreport/image",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.clearAll();
            this.getData();
            this.msg = "Added Successfully";
            this.flag = true;
            this.thumbnail = null;
            this.dialog = false;
          } else {
            this.msg = "Can't Upload Image.. Please Try Again Later";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showsnackbar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadTopImage() {
      this.appLoading = true;
      this.formData.append("image", this.topImage);
      axios({
        method: "POST",
        url: "/static/annualreport/topimage",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showSnackBar = true;
            this.msg = "Added Successfully";
            this.topImage = null;
            this.topImageDialog = false;
          } else {
            this.msg = "Can't Upload Image.. Please Try Again Later";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    deleteItem(id) {
      var data = {};
      data["id"] = id;
      axios({
        url: "/annualreport/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit(id) {
      // this.appLoading=true
      axios({
        method: "post",
        url: "/annualreport/edit",
        data: {
          id: id,
          year: this.editingitem.year,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.year = null;
            this.reportId = response.data.id;
            this.dialogclose();
            if (this.thumbnail) {
              this.uploadImageEdit(id);
            }
            // this.uploadDocumentEdit(id);
          } else {
            axios({
              url: "/annualreport/remove",
              method: "POST",
              data: {
                id: response.data.id,
              },
              headers: {
                token: localStorage.getItem("token"),
              },
            });
            this.msg = "Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    // calc(event) {
    //   return event;
    // },
    uploadDocumentEdit(id) {
      this.progressBar = true;
      this.formData1.append("id", id);
      this.formData1.append("doc", this.selectedFile);

      axios({
        method: "POST",
        url: "/annualreport/doc",
        data: this.formData1,

        onUploadProgress: function (progressEvent) {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // console.log(progressEvent.total);
          this.calc = this.progress;
        }.bind(this),

        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.progressBar = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.calc = 0;
            this.skillFlag = true;
            this.formData1 = new FormData();
            this.selectedFile = null;
            // this.selectFile(null);
            // this.editingitem.file = response.data;

            this.getData();
            this.editdialog = true;
            this.selectedFile = null;
            // this.editdialog = false;
          } else {
            axios({
              url: "/annualreport/remove",
              method: "POST",
              data: {
                id: response.data.id,
              },
              headers: {
                token: localStorage.getItem("token"),
              },
            });
            this.msg = "Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.msg =  "Error";
          this.showsnackbar = true;
          this.progressBar = false;
          console.log(err);
        });
    },
    uploadImageEdit(id) {
      this.appLoading = true;
      this.formData.append("image", this.thumbnail);
      this.formData.append("id", id);
      axios({
        method: "POST",
        url: "/annualreport/image",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.formData = new FormData();
            this.getData();
            this.msg = "Added Successfully";
            this.thumbnail = null;
            this.editdialog = false;
          } else {
            this.msg = "Can't Upload Image.. Please Try Again Later";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showsnackbar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    dialogclose() {
      this.editdialog = false;
      // this.selectedFile = null;
      // this.selectFile(null);
      // this.editingitem.file = null;
      this.flag = false;
      this.flag = true;
      this.editingitem = [];
      this.formData = new FormData();
      this.formData1 = new FormData();
      this.getData()
    },
    clearAll() {
      this.dialog = false;
      this.flag = false;
      this.flag = true;
      // this.selectedFile = null;
      // this.selectFile(null);
      // this.editingitem.file = null;
      // this.editingitem = [];
      this.formData = new FormData();
      this.formData1 = new FormData();
      this.getData()
    },
  },
};
</script>
<style>
.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}
</style>