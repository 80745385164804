<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389  "
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#53a874" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center pb-4 px-3>
      <v-flex v-if="!isProgramAdded" xs12 text-left>
        <span class="itemHeading"> Add New Accomodation </span>
      </v-flex>
      <v-flex v-else xs12 text-left pb-4>
        <span v-if="!slotAddition" class="itemHeading">
          Add New Package Rate
        </span>
        <span v-else class="itemHeading"> Add New Slot </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout v-if="!isProgramAdded" wrap>
          <v-flex xs12 md12>
            <v-layout wrap>
              <v-flex xs12 md12>
                <v-text-field
                  class="textField2"
                  dense
                  v-model="cottages.progName"
                  outlined
                  label="Accomodation Title"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  class="textField2"
                  dense
                  rows="3"
                  v-model="cottages.caption"
                  outlined
                  label="Caption about accomodation"
                  required
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md12 xs12>
            <ImageMultiple
              @stepper="winStepper"
              :height="'400'"
              :width="'600'"
              :heading="'Upload Images'"
            />
          </v-flex>
          <v-flex xs12 pt-5>
            <CoverImageSingle
              @stepper="winStepper"
              :height="'2118'"
              :width="'8001'"
              :componentType="'coverImage'"
              :heading="'Upload Cover Image'"
            />
          </v-flex>

          <v-flex xs12 md12>
            <v-layout wrap justify-center>
              <v-flex xs12 md12 pt-5 text-left>
                <span class="textField1">
                  Please Add the Accomodation Description</span
                >
                <vue-editor
                  class="textField2 pt-1"
                  v-model="cottages.description"
                ></vue-editor>
              </v-flex>
              <v-flex xs12 md12 pt-5 text-left>
                <span class="textField1">Please Add the Notes</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="cottages.notes"
                ></vue-editor>
              </v-flex>
              <v-flex xs12 md12 text-left pt-5>
                <span class="textField1">Please Add the Rules</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="cottages.rules"
                ></vue-editor>
              </v-flex>
              <v-flex xs12 md12 text-left pt-5 v-if="cottages.isCottage">
                <span class="textField1"
                  >Please add the accomodation schedule</span
                >
                <vue-editor
                  class="textField2 pt-1"
                  v-model="cottages.cottage.schedule"
                ></vue-editor>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 v-if="cottages.isCottage" pt-6>
            <v-combobox
              v-model="cottages.cottage.activities"
              :items="items"
              chips
              clearable
              label="Activities provided by accomodation"
              multiple
              solo
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item }}</strong
                  >&nbsp;
                </v-chip>
              </template>
            </v-combobox>
          </v-flex>
          <v-flex xs12 md12>
            <v-layout wrap justify-start>
              <v-flex xs12 md12>
                <v-layout wrap justify-center text-left>
                  <v-flex xs12 md4>
                    <v-text-field
                      class="textField2"
                      dense
                      v-model="cottages.startPoint"
                      outlined
                      label=" Please specify starting location"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4 px-md-3 px-lg-3 px-xl-3>
                    <v-text-field
                      class="textField2"
                      dense
                      v-model="cottages.endPoint"
                      outlined
                      label="Please specify ending location"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      class="textField2"
                      dense
                      v-model="cottages.reportingTime"
                      outlined
                      label="Please specify reporting time"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 md12>
                <v-layout wrap justify-start text-left>
                  <v-flex xs12 md4>
                    <v-text-field
                      class="textField2"
                      type="number"
                      dense
                      v-model="cottages.duration"
                      outlined
                      label="Please specify duration (in Hrs)"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4 px-md-3 px-lg-3 px-xl-3>
                    <v-text-field
                      class="textField2"
                      dense
                      type="text"
                      v-model="cottages.onlinePercent"
                      outlined
                      label="Please specify online percent (in %)"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4 px-md-3 px-lg-3 px-xl-3>
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="cottages.minAge"
                      outlined
                      label="Please specify minimum age"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 md12>
                <v-layout wrap justify-start text-left>
                  <v-flex xs12 md4>
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="cottages.maxAge"
                      outlined
                      label="Please specify maximum age"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4 px-md-3 px-lg-3 px-xl-3>
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="cottages.cottage.guestPerRoom"
                      outlined
                      label="Please specify maximum guest per room"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 md6 text-left>
                <span class="textField1"
                  >Please Select the types of guests you want to include.</span
                >
                <v-layout wrap justify-center pt-5 text-left>
                  <v-flex xs12 md4>
                    <v-checkbox
                      v-model="cottages.bookingAvailability.indian"
                      label="Indian"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 md4 px-md-3 px-lg-3 px-xl-3>
                    <v-checkbox
                      v-model="cottages.bookingAvailability.foreigner"
                      label="Foreigner"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-checkbox
                      v-model="cottages.bookingAvailability.children"
                      label="Children"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span style="font-family: poppinssemibold; font-size: 18px"
                  >Extra Person
                  <span style="font-family: poppinslight; font-size: 14px"
                    >(Please specify maximum guest stay as addition for each
                    section)</span
                  ></span
                >
              </v-flex>
              <v-flex xs12 md12 pt-3>
                <v-layout wrap justify-start text-left>
                  <v-flex xs12 md3 pr-3>
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="cottages.cottage.maxExtraGuestCount"
                      outlined
                      label=" maximum extra guest count"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3 v-if="cottages.bookingAvailability.indian">
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="cottages.cottage.maxExtraIndianCount"
                      outlined
                      label="Maximum indian adult"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="cottages.bookingAvailability.foreigner"
                    xs12
                    md3
                    :px-3="
                      (!cottages.bookingAvailability.children &&
                        cottages.bookingAvailability.foreigner &&
                        !cottages.bookingAvailability.indian) ||
                      (cottages.bookingAvailability.children &&
                        cottages.bookingAvailability.foreigner &&
                        !cottages.bookingAvailability.indian)
                        ? false
                        : true
                    "
                    px-xs-0
                  >
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="cottages.cottage.maxExtraForeignerCount"
                      outlined
                      label="Maximum Foreign adult "
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="cottages.bookingAvailability.children"
                    xs12
                    md3
                    :pl-3="
                      !cottages.bookingAvailability.children ||
                      (cottages.bookingAvailability.children &&
                        cottages.bookingAvailability.indian &&
                        !cottages.bookingAvailability.foreigner) ||
                      (cottages.bookingAvailability.children &&
                        cottages.bookingAvailability.foreigner &&
                        !cottages.bookingAvailability.indian)
                        ? true
                        : false
                    "
                  >
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="cottages.cottage.maxExtraChildrenCount"
                      outlined
                      label="Maximum Childern"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  dark
                  color="#68D389"
                  light
                  :ripple="false"
                  depressed
                  @click="validateInput"
                  class="itemValue"
                >
                  Save
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout v-else wrap justify-center>
          <v-flex xs12 sm12>
            <AddPackage
              v-if="!slotAddition"
              :programId="programId"
              :programData="programData"
              @stepper="winStepper"
            />
          </v-flex>
          <v-flex xs12 sm12>
            <AddSlot
              v-if="slotAddition"
              :programData="programData"
              :programId="programId"
              @stepper="winStepper"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- <v-flex v-if="isProgramAdded" md4 pr-md-3 pr-lg-3 pr-xl-3>
        <cottagesummary />
    
      </v-flex> -->
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import cottagesummary from "./cottagesummary";
import AddSlot from "./../Slots/addSlot";
import AddPackage from "./../Package/addPackage";
import ImageMultiple from "@/components/CommonComponents/multipleImages";
import CoverImageSingle from "@/components/CommonComponents/singleImage";
export default {
  components: {
    // cottagesummary,
    AddSlot,
    AddPackage,
    ImageMultiple,
    CoverImageSingle,
  },
  data() {
    return {
      cottages: {
        progName: null,
        description: null,
        caption: null,
        startPoint: null,
        endPoint: null,
        duration: null,
        onlinePercent: null,
        isCottage: true,
        minGuest: null,
        maxGuest: null,
        maxAge: null,
        minAge: null,
        reportingTime: null,
        rules: null,
        notes: null,
        addons: null,
        category: null,
        cottage: {
          activities: [],
          schedule: null,
          guestPerRoom: null,
          maxExtraGuestCount: null,
          maxExtraIndianCount: null,
          maxExtraForeignerCount: null,
          maxExtraChildrenCount: null,
        },
        bookingAvailability: {
          indian: true,
          foreigner: true,
          children: true,
        },
      },
      programId: null,
      isProgramAdded: false,
      valid: false,
      imageArray: [],
      formData: new FormData(),
      selectedFiles: null,
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      selectedCoverFiles: null,
      coverImage: null,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      slotAddition: false,
      packageRate: [],
      selectedImage: null,
      categories: [],
      programData: [],
      imageFiles: [],
      coverImageFile: [],
      items: ["Accommodation", "Safari", "Trek/Birdwatching", "Bamboo Rafting"],
    };
  },
  mounted() {},
  watch: {
    image() {
      this.image = this.imageArray[0];
    },
  },
  methods: {
    changeImage(index) {
      this.image = this.imageArray[index];
    },
    closeDialoge() {
      this.$emit("stepper", {
        type: "Add",
        getData: false,
        addUser: false,
      });
    },
    winStepper(window_data) {
      if (window_data.type == "AddPackage") {
        this.slotAddition = window_data.slotAddition;
        this.packageRate = window_data.response;
      } else if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
      } else if (window_data.type == "coverImage") {
        this.coverImageFile = window_data.selectedFiles;
      }
    },
    validateInput() {
      if (!this.cottages.progName) {
        this.msg = "Please Provide Program name";
        this.showSnackBar = true;
        return;
      } else if (!this.cottages.description) {
        this.msg = "Please Provide Description";
        this.showSnackBar = true;
        return;
      } else if (!this.cottages.rules) {
        this.msg = "Please Provide Program rules";
        this.showSnackBar = true;
        return;
      } else if (!this.cottages.notes) {
        this.msg = "Please Provide Program notes";
        this.showSnackBar = true;
        return;
      } else if (!this.cottages.startPoint) {
        this.msg = "Please Provide Start ponit";
        this.showSnackBar = true;
        return;
      } else if (!this.cottages.endPoint) {
        this.msg = "Please Provide End point";
        this.showSnackBar = true;
        return;
      } else if (!this.cottages.reportingTime) {
        this.msg = "Please Provide Reporting Time";
        this.showSnackBar = true;
        return;
      } else if (!this.cottages.duration) {
        this.msg = "Please Provide Duration";
        this.showSnackBar = true;
        return;
      } else if (!this.cottages.onlinePercent) {
        this.msg = "Please Provide Online Percent";
        this.showSnackBar = true;
        return;
      } else if (!this.cottages.minAge) {
        this.msg = "Please Provide Minimum age";
        this.showSnackBar = true;
        return;
      } else if (!this.cottages.maxAge) {
        this.msg = "Please Provide Maximum age";
        this.showSnackBar = true;
        return;
      } else if (!this.cottages.cottage.guestPerRoom) {
        this.msg = "Please Provide Guest per Room";
        this.showSnackBar = true;
        return;
      } else if (!this.imageFiles.length > 1) {
        this.msg = "Please Upload Image";
        this.showSnackBar = true;
        return;
      } else if (!this.coverImageFile) {
        this.msg = "Please Upload Cover Image";
        this.showSnackBar = true;
        return;
      } else {
        this.addProgram();
      }
    },
    remove(item) {
      this.cottages.cottage.activities.splice(
        this.cottages.cottage.activities.indexOf(item),
        1
      );
      this.cottages.cottage.activities = [...this.cottages.cottage.activities];
    },
    addProgram() {
      this.cottages.category = "60488cfd18f3076749346702";
      axios({
        method: "post",
        url: "/add/program",
        data: this.cottages,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Program Added Successully";
            this.programData = response.data.data;
            this.programId = response.data.data._id;
            Object.keys(this.cottages).forEach(
              (key) => (this.cottages[key] = null)
            );
            if (this.imageFiles) {
              for (var i = 0; i < this.imageFiles.length; i++)
                this.formData.append("images", this.imageFiles[i]);
              this.uploadMainImages(this.programId);
            }
            if (this.coverImageFile) {
              this.uploadCoverImages(this.programId);
            }
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadMainImages(item) {
      this.appLoading = true;
      this.formData.append("programme", item);
      axios({
        method: "POST",
        url: "/programme/images",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.isProgramAdded = true;
          } else {
            this.msg = "Can't Upload Image.. Please Try Again Later";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showsnackbar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadCoverImages(item) {
      this.appLoading = true;
      this.formDataCover.append("programme", item);
      this.formDataCover.append("image", this.coverImageFile);
      axios({
        method: "POST",
        url: "/programme/UploadCoverImage",
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.isProgramAdded = true;
          } else {
            this.msg = "Can't Upload Cover Image.. Please Try Again Later";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showsnackbar = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}
.v-application .primary {
  background-color: red !important;
}
</style>